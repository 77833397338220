import React from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import Homepage from '../home/Homepage';
import Active from '../home/Active';

const App = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Homepage/>} />
        <Route exact path="/active" element={<Active/>} />
      </Routes>
    </div>
  );
};

export default App;


