import React from 'react';
import api from "../../api/axiosConfig";
import {useState, useEffect} from 'react';
import '../styles/homepage.css';
import PrioritySelector from '../home/PrioritySelector';
import { useUIContext } from '../home/UIContext';
import { useTasks } from './TaskFunctions';
const TaskList = ({ categoryIdInString }) => {
  const { uiState, startAddingTask, startEditingTask, cancelAllOperations } = useUIContext();
  const { tasks, addTask, editTask, deleteTask, completeTask, refreshTasks } = useTasks(categoryIdInString);
  const [newTask, setNewTask] = useState({ name: '', priority: 'LOW', adding: false });

  useEffect(() => {
    const getTasks = async () => {
      try {
        refreshTasks()
      } catch (err) {
        console.log(err);
      }
    };
    if (deletedTask && undoTimeout) {
      performDeleteTask(deletedTask.idInString)
      };
      getTasks();
  }, [categoryIdInString]);

  const handleSaveNewTask = async () => {
    cancelAllOperations();
    if (newTask.name.trim()){
      try {
        addTask(newTask, categoryIdInString);
      } catch (err) {
        console.error("Error adding task:", err);
      }
    }
    setNewTask({ name: '', priority: 'LOW', adding: false });
  };

  const [editingTask, setEditingTask] = useState({ idInString: null, name: '', priority: 'LOW' });
  const handleUpdateTask = async () => {
    cancelAllOperations();
    try {
      editTask(editingTask);
      setEditingTask({ idInString: null }); // Exit edit mode
    } catch (err) {
      console.error("Error updating task:", err);
    }
  };

  const [deletedTask, setDeletedTask] = useState(null);
  const [undoTimeout, setUndoTimeout] = useState(null);
  const handleDeleteClick = async (task) => {
    if (deletedTask && undoTimeout) {
      clearTimeout(undoTimeout); 
      await performDeleteTask(deletedTask.idInString); 
    }
    setDeletedTask(task);
    const timeout = setTimeout(() => {
      performDeleteTask(task.idInString);
      }, 5000); 
    setUndoTimeout(timeout);
  };
  const performDeleteTask = async (taskId) => {
    try {
      setDeletedTask(null);
      clearTimeout(undoTimeout);
      deleteTask(taskId);
    } catch (err) {
      console.error("Error deleting task:", err);
    }
  };
  const handleUndoDelete = () => {
    cancelAllOperations();
    clearTimeout(undoTimeout);
    if (deletedTask) {
      refreshTasks;
      setDeletedTask(null);
    }
  };

  const handleCompleteTask = async (taskId, isComplete) => {
    cancelAllOperations();
    try {
      completeTask(taskId, isComplete);
    } catch (err) {
      console.error("Error updating task completion status:", err);
    }
  }
  
  return (
    <div className="task-item">
      {deletedTask && (
        <div >
          Task deleted. 
          <button className="undo-delete-notification" onClick={handleUndoDelete}>Undo</button>
          <button className="dismiss-delete-notification" onClick={() =>
            performDeleteTask(deletedTask.idInString)}>
              Dismiss
          </button>
        </div>
      )}
      {categoryIdInString !== "All-Tasks" && 
      categoryIdInString !== "Completed-Tasks" &&
      categoryIdInString !== "Active-Tasks" &&
      !uiState.isAddingTask &&  (
        <button onClick={() => {
          startAddingTask();
          setNewTask({ ...newTask, adding: true });
        }} >Add Task</button>
      )}
      
      <div className="task-item">
      {uiState.isAddingTask && (
        <div className="task-item">
          <input
            type="text"
            className="form-control"
            placeholder="Task Name"
            value={newTask.name}
            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            onKeyDown={(e) => e.key === 'Enter' && handleSaveNewTask()}
            autoFocus
          />
          <PrioritySelector
            priority={newTask.priority}
            setPriority={(priority) => setNewTask({ ...newTask, priority })}
          />
          <button onClick={handleSaveNewTask}>Save</button>
          <button onClick={() => {
            cancelAllOperations();
            setNewTask({ name: '', priority: 'LOW', adding: false });
          }}>Cancel</button>
        </div>
      )}
      
      {tasks.filter(task => !deletedTask || task.idInString !== deletedTask.idInString).map((task, index) => (
        <div key={index} className="task-item">
          {uiState.isEditingTask === task.idInString ? (
            // editing fields
            <div>
              <input
                type="text"
                className="form-control"
                value={editingTask.name}
                onChange={(e) => setEditingTask({ ...editingTask, name: e.target.value })}
                onKeyDown={(e) => e.key === 'Enter' && handleUpdateTask()}
                autoFocus
              />
              <PrioritySelector
                priority={editingTask.priority}
                setPriority={(priority) => setEditingTask({ ...editingTask, priority })}
              />
              <button onClick={() => handleUpdateTask()}>Update</button>
              <button onClick={() => {
                cancelAllOperations();
                setEditingTask({ idInString: null, name: '', priority: 'LOW' })}}>Cancel</button>
            </div>
          ) : (
            // Regular task display
            <div className="task-content">
              <h5>
                <div>{task.name}</div>
                <div>priority: {task.priority}</div>
              </h5>
              <h6>status: {task.complete ? "Complete" : "Active"}</h6>
              <div className="task-actions">
                <span className="edit-task-btn" onClick={() => {
                  cancelAllOperations();
                  startEditingTask(task.idInString);
                  setEditingTask({ idInString: task.idInString, name: task.name, priority: task.priority });
                }}>
                  <i className="fas fa-edit"></i>
                </span>
                <span className="delete-task-btn" onClick={(e) => {
                  cancelAllOperations();
                  e.stopPropagation(); 
                  handleDeleteClick(task); 
                }}>
                  <i className="fa-solid fa-trash"></i>
                </span>
                <button onClick={() => handleCompleteTask(task.idInString, task.complete)}>Complete</button>
              </div>
            </div>
          )}
        </div>
      ))}
      </div>

    </div>
  );
};

export default TaskList;