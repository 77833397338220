import React from 'react';
import '../styles/homepage.css'; 
import img1 from '../images/robot_img.png';
import CategoryDropdown from '../Category/Category';

const Active = () => {

  return (
    <div className="bg-dark text-white" style={{ minHeight: '100vh' }}>
      <div className="border-top border-primary p-3 mb-2 bg-dark text-white text-center position-relative">
        <span style={{ fontWeight: 'bold', fontSize: '24px' }}>Task</span>
        <img src={img1} alt="Logo" style={{width: "8%", height: "8%"}} />
        <span style={{ fontWeight: 'bold', fontSize: '24px' }}>Buddy</span>
      </div>
      <div className="bg-dark text-white text-center">
      <CategoryDropdown/>
      </div>
    </div>
  );
};

export default Active;