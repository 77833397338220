const PrioritySelector = ({ priority, setPriority }) => {
    const priorities = ["LOW", "MEDIUM", "HIGH"];
  
    return (
      <div>
        Priority:
        {priorities.map((p) => (
          <button
            key={p}
            style={{
              margin: "0 5px",
              padding: "5px 10px",
              backgroundColor: priority === p ? "#007bff" : "#f8f9fa",
              color: priority === p ? "white" : "black",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
            onClick={() => setPriority(p)}
          >
            {p.charAt(0) + p.toLowerCase().slice(1)}
          </button>
        ))}
      </div>
    );
  };
  export default PrioritySelector;