import api from "./axiosConfig";

export const apiGetTasks = async (categoryId) => {
  const response = await api.get(`/categories/${categoryId}/tasks`);
  return response.data;
};

export const apiAddTask = async (task, categoryId) => {
  const response = await api.post(`/tasks`, {
    name: task.name,
    priority: task.priority, 
    categoryId: categoryId
  });
  return response.data;
};

export const apiEditTask = async (task) => {
  const response = await api.patch(`/tasks/${task.idInString}`, {
    name: task.name,
    priority: task.priority,
  });
  return response.data;
};

export const apiDeleteTask = async (taskId) => {
  const response = await api.delete(`/tasks/${taskId}`);
  return response.data; 
};

export const apiCompleteTask = async (taskId, isComplete) => {
    const response = await api.patch(`/tasks/${taskId}`, {
        complete: !isComplete
      });
    return response.data;
}