import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import Layout from './components/layout/Layout';
import { UIProvider } from './components/home/UIContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UIProvider>
      <BrowserRouter>
        <Routes>
            <Route path = "/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </UIProvider>
  </React.StrictMode>
);

