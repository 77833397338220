import { useState, useEffect } from 'react';
import { apiGetTasks, apiAddTask, apiEditTask, apiCompleteTask, apiDeleteTask } from '../../api/TaskAPI';

export const useTasks = (categoryId) => {
  const [tasks, setTasks] = useState([]);

  // Function to refresh tasks
  const refreshTasks = async () => {
    const fetchedTasks = await apiGetTasks(categoryId);
    setTasks(fetchedTasks);
  };
  useEffect(() => {
    refreshTasks();
  }, [categoryId]);

  // Add a task
  const addTask = async (task, categoryId) => {
    await apiAddTask(task, categoryId);
    await refreshTasks();
  };

  // Edit a task
  const editTask = async (taskData) => {
    await apiEditTask(taskData);
    await refreshTasks();
  };

  // Delete a task
  const deleteTask = async (taskId) => {
    await apiDeleteTask(taskId);
    await refreshTasks();
  };

  // Complete a task
  const completeTask = async (taskId, isComplete) => {
    await apiCompleteTask(taskId, isComplete);
    await refreshTasks();
  };

  return { tasks, addTask, editTask, deleteTask, completeTask, refreshTasks };
};
