import React from 'react';
import TaskList from '../Task/Task';
import api from "../../api/axiosConfig";
import {useState, useEffect} from 'react';
import { useUIContext } from '../home/UIContext';

const CategoryDropdown = () => {
  const { uiState, startAddingCategory, startEditingCategory, cancelAllOperations } = useUIContext();
  const [taskListKey, setTaskListKey] = useState(Date.now());

  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/categories");
        const categoriesWithEditFlag = response.data.map(category => ({
          ...category,
          isEditing: false,
        }));
        setCategories(categoriesWithEditFlag);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCategories();
  }, []);

  const [newCategory, setNewCategory] = useState({ name: '', color: 'white', adding: false });
  const handleAddCategoryClick = () => {
    startAddingCategory();
    setNewCategory({ ...newCategory, adding: true });
  };
  const handleSaveNewCategory = async () => {
    cancelAllOperations();
    if (!newCategory.name.trim()) return; 
    try {
      const response = await api.post("/categories", {
        name: newCategory.name,
        color: newCategory.color, 
      });
      console.log(response.data);
      setCategories([...categories, response.data]);
      setActiveCategory(response.data);
      setNewCategory({ name: '', color: '', adding: false });
    } catch (err) {
      console.error("Error adding category:", err);
    }
  };

  const [editingValues, setEditingValues] = useState({ name: '', color: '' });
  const startEditing = (categoryId) => {
    const category = categories.find(c => c.idInString === categoryId);
    if (category) {
      setEditingValues({ name: category.name, color: category.color });
      setCategories(current => current.map(c => c.idInString === categoryId ? { ...c, isEditing: true } : c));
    }
  };
  const saveChanges = async (categoryId, newName, newColor) => {
    cancelAllOperations();
    if(categoryId == "All-Tasks" || 
        categoryId == "Completed-Tasks" ||
        categoryId == "Active-Tasks"){
        await api.patch(`/categories/Static-Categories/${categoryId}`,{color: newColor});
        setTaskListKey(Date.now());
        setCategories(current =>
          current.map(category =>
            category.idInString === categoryId
              ? { ...category, name: newName, color: newColor, isEditing: false }
              : category
          )
        );
      }
    else{
      try {
        await api.patch(`/categories/${categoryId}`, {
          name: newName,
          color: newColor,
        });
        setTaskListKey(Date.now());
        setCategories(current =>
          current.map(category =>
            category.idInString === categoryId
              ? { ...category, name: newName, color: newColor, isEditing: false }
              : category
          )
        );
      } catch (error) {
        console.error("Error updating category:", error);
      }
    }
  };
  const cancelEdit = (categoryId) => {
    cancelAllOperations();
    setCategories(current => current.map(c => c.idInString === categoryId ? { ...c, isEditing: false } : c));
  };

  const [deletingCategory, setDeletingCategory] = useState(null);
  const handleDeleteClick = (event, category) => {
    event.stopPropagation(); 
    setDeletingCategory(category);
  };
  const confirmDelete = async (category) => {
    try {
      if(category.idInString == "All-Tasks" || 
        category.idInString == "Completed-Tasks" ||
        category.idInString == "Active-Tasks"){
        await api.delete(`/categories/Static-Categories/${category.idInString}`);
      }
      else{
        await api.delete(`/categories/${category.idInString}`);
        setCategories(categories.filter(c => c.idInString !== category.idInString));
    
        if (activeCategory && activeCategory.idInString === category.idInString) {
          setActiveCategory(null); 
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeletingCategory(null); 
      setTaskListKey(Date.now());
    }
  };
  

  return (
    <div>
      <div className="tab-list"> 
        {categories.map((category, index) => (
          uiState.isEditingCategory == category.idInString ? (

            <div key={category.idInString}>
              {!(category.idInString == "All-Tasks" || 
              category.idInString == "Completed-Tasks" || 
              category.idInString == "Active-Tasks") ? (
                <input
                  type="text"
                  defaultValue={category.name}
                  onChange={(e) => setEditingValues({ ...editingValues, name: e.target.value })}
                  onKeyDown={(e) => e.key === 'Enter' && saveChanges(category.idInString, editingValues.name, editingValues.color)}
                  autoFocus
                />) : (
                  <strong>{category.name}</strong>
                )}
              <select
                value={editingValues.color}
                onChange={(e) => setEditingValues({ ...editingValues, color: e.target.value })}
                >
                <option value="white">White</option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
              </select>
              <div>
                <button onClick={() => saveChanges(category.idInString, editingValues.name, editingValues.color)}>Save</button>
                <button onClick={() => cancelEdit(category.idInString)}>Cancel</button>
              </div>
            </div>
          ) : (
          <button
            key={index}
            className={`tab-item ${activeCategory && activeCategory.idInString === category.idInString ? 'active' : ''}`}
            onClick={() => {
              cancelAllOperations();
              setActiveCategory(category)}}
          >
            {category.name}
            {activeCategory && activeCategory.idInString === category.idInString &&
            (
              <>
                <span onClick={(e) => {
                  startEditingCategory(category.idInString);
                  e.stopPropagation();
                  startEditing(category.idInString);
                }}> 🖉 </span>
                <span onClick={(e) => {
                  cancelAllOperations();
                  e.stopPropagation(); 
                  handleDeleteClick(e, category);
                }}> 🗑 </span>
              </>
            )}
          </button> )
        ))}
        {uiState.isAddingCategory && (
          <div >
            <input
              type="text"
              placeholder="Category Name"
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
              onKeyDown={(e) => e.key === 'Enter' && handleSaveNewCategory()}
              autoFocus
            />
            <div>
              Color: 
              <select
                value={newCategory.color}
                onChange={(e) => setNewCategory({ ...newCategory, color: e.target.value })}
              >
                <option value="white">White</option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
              </select>
            </div>
            <div>
              <button onClick={handleSaveNewCategory}>Save</button>
              <button onClick={() => {
                cancelAllOperations();
                setNewCategory({ name: '', color: '', adding: false })}}>Cancel</button>
            </div>
          </div>
        )}

        {!uiState.isAddingCategory && (
          <button onClick={handleAddCategoryClick} className="btn btn-primary">
            + Add Category
          </button>
        )}
      </div>

      {activeCategory && (
        <TaskList key={taskListKey} categoryIdInString={activeCategory.idInString} />
      )}

      {deletingCategory && (
        <div className="modal show" style={{display: 'block'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ color: 'black' }}>Confirm Deletion</h5>
                <button type="button" className="close" onClick={() => setDeletingCategory(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ color: 'black' }}>
                <h4>Are you sure you want to delete '{deletingCategory.name}' category? </h4>
                <p>Note: ALL tasks associated with this category will also be deleted. </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setDeletingCategory(null)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={() => confirmDelete(deletingCategory)}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {deletingCategory && <div className="modal-backdrop show"></div>}


    </div>
  );
};

export default CategoryDropdown;