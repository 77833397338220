import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [uiState, setUIState] = useState({
    isAddingTask: false,
    setEditingTask: null,
    setDeletedTask: null,
    isAddingCategory: false,
    isEditingCategory: null, 
    isDeletingCategory: null,
  });
  const startOperation = (newState) => {
    setUIState({
        isAddingTask: false,
        isEditingTask: null,
        isDeletingTask: null,
        isAddingCategory: false,
        isEditingCategory: null,
        isDeletingCategory: null,
        ...newState,
    });
};

const startAddingTask = () => startOperation({ isAddingTask: true });
const startEditingTask = (taskId) => startOperation({ isEditingTask: taskId });
const startAddingCategory = () => startOperation({ isAddingCategory: true });
const startEditingCategory = (categoryId) => startOperation({ isEditingCategory: categoryId })
const cancelAllOperations = () => startOperation({}); // Resets all operations

  return (
    <UIContext.Provider value={{ uiState, startOperation, startAddingTask, startEditingTask, startAddingCategory, startEditingCategory, cancelAllOperations }}>
      {children}
    </UIContext.Provider>
  );
};
